export type Routes = {
  [k: string]: {
    pathname: string;
  };
};

export const ROUTES: Routes = {
  HOME: {
    pathname: '/',
  },
  BOOKING: {
    pathname: '/cerca',
  },
  CHECKOUT_RESERVATION: {
    pathname: '/checkout/prenota',
  },
  CHECKOUT_RECAP: {
    pathname: '/checkout/riepilogo',
  },
  RESERVATION_MANAGE: {
    pathname: '/prenotazioni/gestisci',
  },
  RESERVATION_DELETE: {
    pathname: '/prenotazioni/cancella',
  },
  RESERVATION_EDIT: {
    pathname: '/prenotazioni/modifica',
  },
  CLINIC: {
    pathname: '/centri-medici',
  },
  SPECIALISTA: {
    pathname: '/specialista',
  },
  PRIVACY_POLICY: {
    pathname: '/privacy-policy',
  },
  PRIVACY_INFO: {
    pathname: '/informative/dav_infc_pren_01',
  },
  TERMIN_AND_CONDITION: {
    pathname: '/termini-e-condizioni-del-servizio',
  },
  TANTOSVAGO_SSO: {
    pathname: '/tantosvago/sso',
  },
  NOT_FOUND: {
    pathname: '/404',
  },
  STRUCTURES: {
    pathname: '/centri-medici',
  },
  EXAM_AND_SERVICES: {
    pathname: '/esami-e-servizi',
  },
  PRESTAZIONE: {
    pathname: '/prenota-visita',
  },
};

export const ROUTES_PRESTAZIONE = (speciality: string, city: string) => `${ROUTES.PRESTAZIONE.pathname}/${speciality}/${city}`;

export const getRoute = (route: string) => {
  if (route === ROUTES.HOME.pathname) {
    return ROUTES.HOME;
  }
  for (const name in ROUTES) {
    if (route.includes(ROUTES[name].pathname) && name !== 'HOME') {
      return ROUTES[name];
    }
  }
  return ROUTES.NOT_FOUND;
};
