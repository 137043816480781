import { DAVINCI_CDN } from '@/costants/defaultValues';
import { NoSpacesString } from '@/types';
import { SearchLocation } from '@/utils/search';
import Head from 'next/head';
import { ReactNode } from 'react';

type OpenGraph = {
  title?: string;
  url?: string;
  description?: string;
  image?: string;
};

export type SEO = {
  title?: string;
  description?: string;
  robots?: string;
  canonicalUrl?: string;
  openGraph?: OpenGraph;
  location?: SearchLocation;
};

interface Props<T extends string> {
  className?: string;
  children?: ReactNode;
  dataCy?: NoSpacesString<T>;
  metadata?: SEO;
  customPageStyles?: string;
}

const PageContainer = <T extends string>({
  className,
  children,
  dataCy = 'page-container' as NoSpacesString<T>,
  metadata,
  customPageStyles,
}: Props<T>) => {
  if (!children) {
    return null;
  }

  const openGraphUrl = metadata?.openGraph?.url || metadata?.canonicalUrl;

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=5.0" />
        <link rel="icon" href="/favicon.ico" />
        <title>{metadata?.title}</title>

        {/* Basic */}
        <meta name="description" content={metadata?.description} />
        <meta name="robots" content={metadata?.robots ?? 'index, follow'} />
        {metadata?.canonicalUrl && <link rel="canonical" href={metadata?.canonicalUrl} />}

        {/* Open Graph */}
        <meta property="og:description" content={metadata?.openGraph?.description ?? metadata?.description} />
        <meta property="og:title" content={metadata?.openGraph?.title ?? metadata?.title} />
        {openGraphUrl && <meta property="og:url" content={openGraphUrl} />}
        <meta property="og:image" content={metadata?.openGraph?.image ?? `${DAVINCI_CDN}/inclinic/images/hero.jpg`} />
        {customPageStyles && <style>{customPageStyles}</style>}

        {/* Open Twitter */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@DaVinciSalute" />
        <meta name="twitter:creator" content="@DaVinciSalute" />

        {/* Geo */}
        {metadata?.location?.name && <meta name="geo.placename" content={`${metadata?.location?.name}, Italia`} />}
        {metadata?.location?.latitude && metadata?.location.longitude && (
          <meta name="geo.position" content={`${metadata.location.latitude}, ${metadata.location.longitude}`} />
        )}
      </Head>
      <div className={className} data-cy={dataCy}>
        {children}
      </div>
    </>
  );
};

export default PageContainer;
