import { ELLink } from '@davincihealthcare/elty-design-system-react';
import Link from 'next/link';
import { ComponentPropsWithoutRef } from 'react';

type SimpleNavLinkProps = { href: string } & ComponentPropsWithoutRef<typeof ELLink>;

const SimpleNavLink = ({ href, ...rest }: SimpleNavLinkProps) => (
  <Link href={href} legacyBehavior>
    <ELLink href={href} {...rest} />
  </Link>
);

export default SimpleNavLink;
