import { NoSpacesString } from '@/types';
import { ReactNode } from 'react';

interface Props<T extends string> {
  className?: string;
  children?: ReactNode;
  dataCy?: NoSpacesString<T>;
}

const Container = <T extends string>({ className, children, dataCy = 'container' as NoSpacesString<T> }: Props<T>) => {
  if (!children) {
    return null;
  }
  return (
    <div className={className} data-cy={dataCy}>
      <div className="container mx-auto min-h-[inherit] px-1 md:px-2 lg:px-4">{children}</div>
    </div>
  );
};

export default Container;
