'use client';
import { useTantoSvagoStore } from '@/store';
import { useEffect, useState } from 'react';

export const useIsTantosvago = () => {
  const { apiHeaders } = useTantoSvagoStore();
  const [isTantosvago, setIsTantosvago] = useState(false);
  // On server tantoSvago is always false, we do like this to avoid hydration mismatch
  useEffect(() => {
    setIsTantosvago(!!apiHeaders.Authorization);
  }, [apiHeaders.Authorization]);

  return isTantosvago;
};
